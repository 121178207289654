<template>
  <v-card v-if="canAccessPage" class="card-size" style="width: 100%;">
    <produto-sidebar
      v-if="canAccessCRUD && tipoImportacao"
      ref="sidebar">
    </produto-sidebar>

    <produto-tabela
      ref="tabelaProduto"
      v-if="canSearch"
      :importacao="true"
      :exportacao="true"
      @PRODUTOTABELA_EDITAR="editarItem"
      @TABELA_EXPORTAR_DADOS="requestExportacao"
      @TABELA_IMPORTAR_DADOS="$refs.sidebar.open()"
      @PRODUTOTABELA_VISUALIZAR="visualizarItem"/>

    <v-card-actions v-if="canPost">
      <v-row>
        <v-spacer></v-spacer>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn fab
              bottom right fixed
              class="v-btn__fab"
              color="primary"
              v-on="on"
              @click.native="$router.push({ name: 'novoProduto' })">
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <span>{{$t('label.novo_produto')}}</span>
        </v-tooltip>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import exportacao from '../../common/functions/exportacao';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import ProdutoTabela from './ProdutoTabela';
import ProdutoSidebar from './ProdutoSidebar';

export default {
  name: 'ProdutoList',
  data() {
    return {
    };
  },
  components: {
    ProdutoTabela,
    ProdutoSidebar,
  },
  computed: {
    ...generateComputed('prd', [
      'canAccessPage',
      'canAccessCRUD',
      'canPost',
      'canSearch',
    ]),
    ...mapGetters('importacao', {
      tiposImportacao: 'getTiposImportacao',
    }),
    tipoImportacao() {
      return this.tiposImportacao.find((el) => el.nome === 'importacao_produto');
    },
  },
  methods: {
    requestExportacao(cb) {
      const params = {},
        filtroParam = this.$refs.tabelaProduto.getSearchQuery();
      if (filtroParam) {
        params.filtro = filtroParam;
      }
      exportacao.exportar(cb, 'produto_pasta_trabalho', this, params, 5000, 0);
    },
    editarItem(item) {
      this.$router.push({ name: 'editarProduto', params: { id: item.id } });
    },
    visualizarItem(item) {
      this.$router.push({ name: 'verProduto', params: { id: item.id } });
    },
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast('Acesso negado');
      }
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => {
        this.accessForbidden();
      }, 1E3);
    }
  },
};
</script>
